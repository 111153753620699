module.exports = {
  project: require('../projects/antisound'),
  slug: 'five-wounds',
  title: 'Five Wounds',
  date: '1998-06-01',
  label: 'Embryo Recordings',
  player: '',
  image: '',
  links: [
  ]
}
