module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'solar-winds-white-noise-antigravity',
  title: 'Solar Winds, White Noise, Antigravity',
  date: '2000-04-01',
  label: 'Embryo Recordings',
  player: '3057281696',
  image: '/images/abm-solar-winds-white-noise-antigravity.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/solar-winds-white-noise-antigravity',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/6QJ1aMYLUJHBNlNV3Js5cw',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/solar-winds-white-noise-antigravity/1507613987',
    }
  ]
}
