module.exports = {
  project: require('../projects/siilt'),
  slug: '002',
  title: '002',
  date: '2017-07-01',
  label: '(i) equals x',
  player: '586878254',
  image: '/images/siilt-002.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://siilt.bandcamp.com/album/002',
    }
  ]
}
