module.exports = {
  project: require('../projects/halo'),
  slug: 'body-of-light',
  title: 'Body of Light',
  date: '2003-07-01',
  label: 'Relapse Records',
  player: '4293096277',
  image: '/images/halo-body-of-light.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://haloband.bandcamp.com/album/body-of-light',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/0PTTvhuu7EWeYFrewlqDZZ',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/au/album/body-of-light/172992545',
    },
  ]
}
