import React from 'react'
import { Link } from 'gatsby'
import Helpers from '../helpers'
import Layout from '../components/layout'

export default () => {
  const projects = Helpers.getProjects();
  
  return (
    <Layout title="Projects">
      <h1>
        Projects
      </h1>
      <ul>
        {projects.map((project, index) => {
          return (
            <li 
              className="list__item"
              key={index}>
              <Link to={`/projects/${project.slug}`}>
                {project.name}
              </Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}
