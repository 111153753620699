module.exports = {
  project: require('../projects/halo'),
  slug: 'subliminal-transmissions',
  title: 'Subliminal Transmissions',
  date: '1998-01-01',
  label: 'Embryo Recordings',
  player: '520772561',
  image: '/images/halo-subliminal-transmissions.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://fearhatelies.bandcamp.com/album/halo-subliminal-transmissions',
    }
  ]
}
