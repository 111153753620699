module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'we-have-become-oceans',
  title: 'We Have Become Oceans',
  date: '2021-06-21',
  label: '(i) equals x',
  player: '4250756599',
  image: '/images/abm-we-have-become-oceans.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/we-have-become-oceans',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/6Fy6KdqntyxWg5q6wkFytA',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/we-have-become-oceans/1573614238',
    }
  ]
}
