module.exports = {
  project: require('../projects/filament-twine'),
  slug: 'unpermanent',
  title: 'Unpermanent',
  date: '2018-10-01',
  label: '(i) equals x',
  player: '426939511',
  image: '/images/ft-unpermanent.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://ftwine.bandcamp.com/album/unpermanent',
    }
  ]
}
