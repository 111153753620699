module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'a-beautiful-machine',
  title: 'A Beautiful Machine',
  date: '2000-01-01',
  label: 'Embryo Recordings',
  player: '3652497228',
  image: '/images/abm-a-beautiful-machine.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/a-beautiful-machine',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/4mdd157FclYYQ8qoeNDPJr',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/a-beautiful-machine/1507693715',
    }
  ]
}
