module.exports = {
  project: require('../projects/filament-twine'),
  slug: 'landlocked',
  title: 'Landlocked',
  date: '2023-03-09',
  label: '(i) equals x',
  player: '3842217478',
  image: '/images/ft-landlocked.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://ftwine.bandcamp.com/album/landlocked',
    }
  ]
}
