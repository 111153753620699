module.exports = {
  project: require('../projects/filament-twine'),
  slug: 'woven',
  title: 'Woven',
  date: '2019-10-01',
  label: '(i) equals x',
  player: '4121411862',
  image: '/images/ft-woven.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://ftwine.bandcamp.com/album/woven',
    }
  ]
}
