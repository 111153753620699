module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'king-tide',
  title: 'King Tide',
  date: '2020-04-01',
  label: '(i) equals x',
  player: '3371934409',
  image: '/images/abm-king-tide.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/king-tide',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/4AIUnjtBbecIlUCxPPMsfI',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/king-tide/1506397457',
    }
  ]
}
