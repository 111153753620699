module.exports = {
  project: require('../projects/halo'),
  slug: 'degree-zero-point-of-implosion',
  title: 'Degree Zero Point of Implosion',
  date: '2000-02-01',
  label: 'Embryo Recordings',
  player: '2245726508',
  image: '/images/halo-degree-zero-point-of-implosion.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://fearhatelies.bandcamp.com/album/degree-zero-point-of-implosion',
    }
  ]
}
