module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'home',
  title: 'Home',
  date: '2000-09-01',
  label: 'Embryo Recordings',
  player: '3057281696',
  image: '/images/abm-home.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/home',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/19nT1nMBXn8LN3Q4ZBy7jW',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/home/1507615127',
    }
  ]
}
