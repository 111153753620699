module.exports = {
  project: require('../projects/halo'),
  slug: 'massive-corporate-disease',
  title: 'Massive Corporate Disease',
  date: '1999-07-01',
  label: 'Embryo Recordings',
  player: '2798155699',
  image: '/images/halo-massive-corporate-disease.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://fearhatelies.bandcamp.com/album/massive-corporate-disease',
    }
  ]
}
