module.exports = {
  project: require('../projects/halo'),
  slug: 'gods-of-sound',
  title: 'Gods of Sound',
  date: '2014-07-01',
  label: '(i) equals x',
  player: '2245726508',
  image: '/images/halo-gods-of-sound.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://fearhatelies.bandcamp.com/album/gods-of-sound',
    }
  ]
}
