module.exports = {
  project: require('../projects/halo'),
  slug: 'guattari',
  title: 'Guattari (From the West Flows Grey Ash and Pestilence)',
  date: '2001-07-01',
  label: 'Relapse Records',
  player: '1044512880',
  image: '/images/halo-guattari.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://haloband.bandcamp.com/album/guattari-from-the-west-flows-grey-ash-and-pestilence',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/0GxZhfPuzLl99TBcI5vjr8',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/au/album/guattari-from-west-flows-grey/75088431',
    },
  ]
}
