module.exports = {
  project: require('../projects/anti-sound-syndicate'),
  slug: 'vs-atomizer',
  title: 'Vs Atomizer',
  date: '2000-07-01',
  label: 'Embryo Recordings',
  player: '1538864655',
  image: '/images/ass-vs-atomizer.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://antisoundsyndicate.bandcamp.com/album/vs-atomizer',
    }
  ]
}
