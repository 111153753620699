module.exports = {
  project: require('../projects/tether'),
  slug: 'sines',
  title: 'Sines',
  date: '2010-07-01',
  label: '(i) equals x',
  player: '2079661250',
  image: '/images/tether-sines.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://tether.bandcamp.com/album/sines',
    }
  ]
}
