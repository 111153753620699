module.exports = {
  project: require('../projects/siilt'),
  slug: 'schism-tapes',
  title: 'Schism Tapes',
  date: '2011-10-01',
  label: '(i) equals x',
  player: '939173173',
  image: '/images/siilt-schism-tapes.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://siilt.bandcamp.com/album/schism-tapes',
    }
  ]
}
