module.exports = {
  project: require('../projects/a-beautiful-machine'),
  slug: 'another-time',
  title: 'Another Time',
  date: '2003-01-01',
  label: 'Embryo Recordings',
  player: '653071050',
  image: '/images/abm-another-time.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://abeautifulmachine.bandcamp.com/album/another-time',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/0koVgKNW9JYoMNCveUKLW8',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/us/album/another-time/1507618652',
    }
  ]
}
