module.exports = {
  project: require('../projects/anti-sound-system'),
  slug: 'deep-black-static',
  title: 'Deep Black Static',
  date: '2000-07-01',
  label: 'Embryo Recordings',
  player: '',
  image: '',
  links: [
  ]
}
