module.exports = {
  project: require('../projects/terminal-sound-system'),
  slug: 'rh-8sb',
  title: 'RH-8SB',
  date: '2001-07-01',
  label: 'Relapse Records / Release Entertainment',
  player: '778553915',
  image: '/images/tss-rh-8sb.jpg',
  links: [
    {
      title: 'Bandcamp',
      href: 'https://terminalsoundsystem.bandcamp.com/album/rh-8sb',
    },
    {
      title: 'Spotify',
      href: 'https://open.spotify.com/album/1NVMAHyMdjNQhvP7UvEF1I',
    },
    {
      title: 'Apple Music',
      href: 'https://music.apple.com/album/rh-8sb/173497847',
    },
  ]
}
